.quest{
    background-color: #e5decf;
    padding: 15px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.questLog{
    background-color:#eee7d7;
    color:#2c2415;
}


.quest-failed{
    /* color: #d43d3d; */
}
.quest-completed{
   /* color: #6aad4b; */
}

.icon{
    font-size: 15pt;
}

.add-quest{
    margin-bottom:20px;
    
}

.golden{
    color: #d1b513;
}

.quest-container-failed{
    background-color: #d43d3d;
    color: #f6eee3;
}
.quest-container-completed{
    background-color: #6aad4b;
}