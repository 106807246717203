body {
    background:#282c34;
}

.dnd{
    color:white !important;
    margin-bottom: 50px;
}

.dnd .text-muted{
    color: #999 !important;
}