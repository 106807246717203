.cell {
  height: 80px;
  border: solid thin red;
  text-align: center;
}

#play-area {
}

.flex-grid {
  display: flex;
}

.col {
  flex: 1;
  padding: 0;
}
