.cah-players {
  background-color: red;
}

.cah-card {
  display: inline-block;
  border: solid thin #333;
  text-align: center;
  padding: 15px;
  color: black;
  background-color: white;
  transition: 0.2s;
  cursor: pointer;
}

.cah-card:hover {
  margin-top: -10px;
}

.selected {
  background-color: red;
}
