.App {
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  padding-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.questionContainer {
  height: 70vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}

.questionContainer:hover {
  opacity: 0.8;
}
.question {
  color: white;
  font-size: 20pt;
}

.status-text {
  color: #c1c1c1;
  text-align: center;
}

.players .player {
  padding: 10px;
  font-size: 16pt;
  margin-right: 5px;
}

.players .delete {
  margin-left: 5px;
  cursor: pointer;
}

.game {
  width: 100%;
  padding: 30px;
}

.rooms-status {
  float: right;
  font-size: 20pt;
  margin: 5px 0px;
  color: #28a745;
}

.private {
  color: #dc3545;
}

.switch {
  position: relative;
  display: inline-block;
  margin-left: 10px;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.text-highlight {
  background-color: #007bff;
  color: white;
}

.roadmap {
  margin-bottom: 50px;
}

.entry {
  background-color: rgba(255, 255, 255, 0.9);
  text-align: left;
  padding: 10px 30px;
  border: 10px solid #282c34;
  color: black;
  border-radius: 20px;
}

/* .right {
  text-align: left;
  margin-right: -200px;
  padding-left: 80px;
}
.left {
  text-align: right;
  margin-left: -200px;
  padding-right: 80px;
} */

.entry .badge {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.title {
  display: inline-block;
  font-weight: 600;
}

.nav-item .active {
  background-color: white;
  color: #282c34 !important;
  border-radius: 10px;
}

.white {
  color: white;
}
