.player {

}

.alive {

}


.dead {
    opacity: 0.2;
}

.btn-block {
  display: block;
  width: 100%;
}

.hidden{
    display:none;
}

.player-border{
    border: solid thin #ccc;
    border-radius: 16px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}


.asset__item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  border-radius: 16px;
  margin-top: 20px;
  background: #202020;
  min-height:200px;
}
.asset__item img {
  max-height:200px;
  min-height:200px;
  min-width: 180px;
    border-radius: 16px;
}

.logo{
    min-width: 100%;
}

.big{
    min-width: 100%;
}

.container-big{
    display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30%;
}