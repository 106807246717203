.h1 {
  display: inline-block;
}

ul {
  list-style: "none";
}

.padding {
  padding: 30px;
}

.item {
  border: solid thin white;
  padding: 20px 15px;
}

.red {
  background-color: red;
  color: white;
}

.blue {
  background-color: blue;
  color: white;
}

.green {
  background-color: green;
  color: white;
}

.yellow {
  background-color: yellow;
  color: black;
}

.pink {
  background-color: pink;
  color: black;
}

.orange {
  background-color: orange;
  color: white;
}

.orderBar {
  margin-bottom: 20px;
}

.price {
  font-size: 20pt;
  text-align: left;
}

.left {
  text-align: left;
}
